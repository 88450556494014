import React from 'react';
import './App.css';  // Importing global styles
import ContactSection from "./components/ContactSection"

import logo from "./assets/logo.svg"

function App() {
  return (
    <div className="container">
      <div className="content">
        <img src={logo} alt="Govorček" className="logo" />
        <h1>STRANICA U IZRADI</h1>
        <ContactSection />
      </div>
    </div>
  );
}

export default App;
