import React from 'react';
import './ContactSection.css';  // Import the ContactSection CSS file

const ContactSection = () => {
  return (
    <div className="contact-grid">
      <div className="contact-item">
        <h2>Adresa</h2>
        <p>Fancevljev prilaz 4, 10000, Zagreb</p>
      </div>

      <div className="contact-item">
        <h2>Kontakt</h2>
        <p>info.govorcek@gmail.com</p>
        <p>(+385)&nbsp;97&nbsp;669&nbsp;1876</p>
      </div>

      <div className="contact-item">
        <h2>Prati nas</h2>
        <p><a href="https://www.facebook.com/profile.php?id=61567144255546" target="_blank" rel="noopener noreferrer">Facebook</a></p>
        <p><a href="https://www.instagram.com/logo_govorcek" target="_blank" rel="noopener noreferrer">Instagram</a></p>
      </div>
    </div>
  );
};

export default ContactSection;
